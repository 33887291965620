<template>
  <div class="deal-products">
    <v-btn class="evi-button-green evi-button-green--fill mt-10 mb-10" @click="$emit('openModalCreate')" v-if="hasRight('deals.manage')">
      Добавить продукт
    </v-btn>
    <v-dialog
        v-model="editProductModal"
        max-width="612px"
    >
      <AddProduct @close="editProductModal = false" :dealId="dealId" :dealProducts="products"
                  :editMode="editModeProduct" :editedProduct="product" @editModeOff="editModeProductOff"/>
    </v-dialog>
    <v-dialog
        v-model="deleteProductModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление продукта "{{ product.name }}" из сделки
        </v-card-title>
        <v-card-text>Вы действительно хотите убрать продукт из сделки?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="deleteProductModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="remove"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="deal-product" v-for="(item, index) in localProducts" :key="index">
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <div class="deal-product__dots" v-on="on" :bind="attrs" v-if="canEdit">
            <v-icon>mdi-dots-horizontal</v-icon>
          </div>
        </template>
        <v-list class="deal-product__menu">
          <v-list-item-group>
            <v-list-item @click="editProductModalOpen(item)">
              <v-list-item-icon class="mr-2">
                <v-icon color="#008A80">mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Редактировать</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteProductModalOpen(item)">
              <v-list-item-icon class="mr-2">
                <v-icon color="#ED4245">mdi-trash-can-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Удалить</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <div class="deal-product__body">
        <v-select
            class="evi-select"
            color="#44D370"
            v-model="item.type"
            :items="types"
            item-text="name"
            item-value="value"
            label="Тип"
            item-color="green"
            readonly
        >
          <template v-slot:append>
              <span class="evi-select__arrow" v-show="false">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
          </template>
        </v-select>
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="item.name"
            disabled
        >
          <template v-slot:label>
            Название {{ item.type === 'product' ? 'товара' : 'услуги' }}
          </template>
        </v-text-field>
        <div class="item-page__text-field mb-7" v-if="item.deal_product_user">
          <small>Ответственный</small>
          <div class="d-flex align-center mt-2 pointer" @click="openUserPage(item.deal_product_user.id)">
            <v-avatar
                color="#FFFFFF"
                size="30"
                class="user-page__avatar mr-3"
            >
              <img
                  :src="item.deal_product_user.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                  alt=""
              >
            </v-avatar>
            <span>{{item.deal_product_user.name}}</span>
          </div>
        </div>
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-if="item.deal_product_size"
            v-model="item.deal_product_size.value"
            disabled
        >
          <template v-slot:label>
            Размер
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="item.count"
            type="number"
            @change="editProductItem(item)"
            :disabled="!canEdit"
        >
          <template v-slot:label>
            Количество
          </template>
        </v-text-field>
        <div class="deal-product__price mb-10">
          <span class="price-title">Стоимость за единицу</span>
          <span class="price-value">{{ item.deal_price | VMask(priceMask) }}</span>
        </div>
        <div class="deal-product__price">
          <span class="price-title">Стоимость всего</span>
          <span class="price-value">{{ item.deal_price * item.count | VMask(priceMask) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {mapActions, mapState} from "vuex";
import {hasRight} from '@/utils/access/hasRight';
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import AddProduct from "@/components/deals/AddProduct";

const priceMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowNegative: true,
});

export default {
  name: "Products",
  components: {AddProduct},
  props: {
    products: {
      type: Array,
    },
    dealId: {
      type: Number,
    },
    canEdit: {
      type: Boolean,
      default: true,
    }
  },
  mixins: [OpenPagesMixin],
  data: () => ({
    priceMask,
    deleteProductModal: false,
    editProductModal: false,
    editModeProduct: false,
    product: {},
    localProducts: [],
  }),
  computed: {
    ...mapState('products', ['types'])
  },
  methods: {
    ...mapActions({
      editProduct: 'deals/editProduct',
      deleteProduct: 'deals/deleteProduct',
    }),
    hasRight: hasRight,
    editProductItem(item) {
      let payload = {
        dealId: this.dealId,
        data: {
          rel_id: item.rel_id,
          price: item.deal_price || item.price,
          count: item.count,
        },
      };

      this.editProduct(payload).then(() => {
        this.$emit('getData');
      });
    },
    deleteProductModalOpen(item) {
      this.product = item;
      this.deleteProductModal = true;
    },
    editProductModalOpen (item) {
      this.product = item;
      this.editProductModal = true;
      this.editModeProduct = true;
    },
    editModeProductOff () {
      this.editModeProduct = false;
      this.product = {};
    },
    remove() {
      let payload = {
        dealId: this.dealId,
        data: {
          rel_id: this.product.rel_id,
          product_id: this.product.id,
        },
      };

      this.deleteProduct(payload).then(() => {
        this.$emit('getData');
      });
    },
  },
  mounted() {
    this.localProducts = this.products;
  },
  watch: {
    products: {
      handler: function (val) {
        if (val.length) {
          this.localProducts = val;
        }
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.deal-products {
  width: 100%;
  max-width: 727px;
}
</style>
