<template>
  <div class="item-page">
    <div class="item-page__header" v-if="loading">
      <v-skeleton-loader
          type="list-item-avatar-three-line"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__body" v-if="loading">
      <v-skeleton-loader
          type="list-item, list-item, list-item, list-item"
          width="50%"
          height="100%"
      />
      <v-skeleton-loader
          type="list-item-avatar, list-item-avatar"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__header" v-if="!loading">
      <img :src="require('@/assets/img/pages/deal.png')" alt="" class="mr-9">
      <div class="item-page__name pointer">
        <div>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="deal.name"
              disabled
          >
            <template v-slot:label>
              Название (номер)
            </template>
          </v-text-field>
        </div>
        <div class="item-page__actions  mt-1">
          <v-btn text class="evi-button-text" @click="addProductModal = true" v-if="hasRight('deals.manage')">
            <img width="19" class="mr-2" :src="require('@/assets/img/menu/products.svg')" alt="">
            Добавить продукт в корзину
          </v-btn>
          <v-btn text class="evi-button-text" @click="addFieldModal = true"
                 v-if="hasRight('deals.manage')">
            <img width="19" class="mr-2" :src="require('@/assets/img/clients/field.svg')" alt="">
            Добавить поле
          </v-btn>
          <v-btn text class="evi-button-text" @click="createBillModal = true"
                 v-if="hasRight('bills.manage') && hasRight('deals.manage')">
            <img width="19" class="mr-2" :src="require('@/assets/img/menu/bills.svg')" alt="">
            Выставить счёт
          </v-btn>
          <v-btn text class="evi-button-text" @click="removeDealModal = true" v-if="hasRight('deals.delete')">
            <img width="19" class="mr-2" :src="require('@/assets/img/users/red.svg')" alt="">
            Удалить
          </v-btn>
        </div>
      </div>
      <div class="item-page__close" @click="$router.go(-1)">
        <v-icon size="20">mdi-close</v-icon>
      </div>
    </div>
    <div class="item-page__body" v-if="!loading">
      <div class="item-page__edit">
        <v-text-field
            class="evi-text-field mb-6"
            color="#44D370"
            v-model="deal.id"
            disabled
            hide-details
        >
          <template v-slot:label>
            ID сделки
          </template>
        </v-text-field>
        <div class="pointer" @click="openClientPage(deal.client.id)">
          <v-text-field
              class="evi-text-field mb-5"
              color="#44D370"
              v-if="deal.client"
              v-model="deal.client.id"
              disabled
              hide-details
          >
            <template v-slot:label>
              ID клиента
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="deal.client.name"
              disabled
              v-if="deal.client"
              hide-details
          >
            <template v-slot:label>
              Клиент
            </template>
          </v-text-field>
        </div>
        <v-text-field
            class="evi-text-field mb-11"
            color="#44D370"
            v-model="name"
            v-if="deal.name"
            @change="editDealPage"
            :disabled="!hasRight('deals.manage')"
            hide-details
        >
          <template v-slot:label>
            Название сделки (номер)
          </template>
        </v-text-field>
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            v-model="executor"
            :items="localUsers"
            item-text="name"
            item-value="id"
            @change="editDealPage"
            :disabled="!hasRight('deals.manage')"
            :loading="userLoading"
            @focus="loadUsers"
            :search-input.sync="userSearch"
            @input.native="searchUser(userSearch)"
            clearable
            item-color="green"
            hide-details
        >
          <template v-slot:label>
            Исполнитель
          </template>
          <template v-slot:append-item>
            <div v-intersect="userIntersect"/>
          </template>
          <template v-slot:item="{item}">
            <v-avatar
                color="#FFFFFF"
                size="30"
                class="user-page__avatar mr-3"
            >
              <img
                  :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                  alt=""
              >
            </v-avatar>
            <div class="v-list-item__title">{{ item.name }}</div>
          </template>
          <template v-slot:append>
                        <span class="evi-select__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
          </template>
        </v-autocomplete>
        <div class="item-page__text-field mb-7">
          <small class="mb-2">Участники</small>
          <div class="d-flex align-center">
            <div class="items">
              <v-menu
                  top
                  offset-y
                  max-height="156"
                  open-on-hover
                  v-for="user in dealUsers"
                  :key="user.id"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                      color="#FFFFFF"
                      size="45"
                      class="user-page__avatar mr-2 mb-2"
                      @click="openUserPage(user.id)"
                      v-bind="attrs" v-on="on"
                  >
                    <img
                        :src="user.avatar_url || require('@/assets/img/users/profile-avatar.png')"
                        alt=""
                    >
                  </v-avatar>
                </template>
                <v-list-item>{{ user.name }}</v-list-item>
              </v-menu>
            </div>
          </div>
        </div>
        <v-autocomplete
            class="evi-autocomplete mb-7"
            color="#44D370"
            :items="localSubdivisions"
            v-model="subdivision"
            item-text="name"
            item-value="id"
            @change="editDealPage"
            :disabled="!hasRight('deals.manage')"
            :loading="subdivisionLoading"
            @focus="loadSubdivisions"
            :search-input.sync="subdivisionSearch"
            @input.native="searchSubdivision(subdivisionSearch)"
            clearable
            item-color="green"
            hide-details
        >
          <template v-slot:label>
            Ответственное подразделение
          </template>
          <template v-slot:append-item>
            <div v-intersect="subdivisionIntersect"/>
          </template>
          <template v-slot:append>
            <span class="evi-autocomplete__arrow">
                <v-icon>mdi-chevron-down</v-icon>
            </span>
          </template>
        </v-autocomplete>
        <v-autocomplete
            class="evi-autocomplete mb-5"
            color="#44D370"
            :items="localStatuses"
            v-model="status"
            item-text="name"
            item-value="id"
            @change="editDealPage"
            :disabled="!hasRight('deals.manage')"
            clearable
            item-color="green"
            hide-details
        >
          <template v-slot:label>
            Статус
          </template>
          <template v-slot:append>
                        <span class="evi-autocomplete__arrow">
                            <v-icon>mdi-chevron-down</v-icon>
                        </span>
          </template>
          <template v-slot:selection="{item}">
            <div class="modal-create__color-select">
              <span class="color" :style="{backgroundColor: `${item.color}`}"/>
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item="{item}">
            <div class="modal-create__color-select">
              <span class="color mt-1" :style="{backgroundColor: `${item.color}`}"/>
              {{ item.name }}
            </div>
          </template>
        </v-autocomplete>
        <div class="item-page__text-field mb-7">
          <small>Статус был обновлен</small>
          <div class="d-flex align-center">
            <span>{{deal.current_status_apply_date | moment("from", "now")}}</span>
          </div>
        </div>
        <CustomFields :items="dealCustomFields" :fieldDisabled="!hasRight('deals.manage')"
                      :canRemoveField="hasRight('deals.manage') && hasRight('deals.viewAll') && hasRight('deals.delete')"
                      @removeField="removeField" @editField="editField" @change="changeCustomField"/>
        <div v-if="hasRight('clients.manage')" class="add-field pointer mb-10" @click="addFieldModal = true">
          <v-icon>mdi-plus</v-icon>
          Добавить поле
        </div>
        <div class="mb-11">
          <h4 class="mb-5">Итого</h4>
          <div class="item-page__text-field mb-7">
            <small>Сумма корзины</small>
            <div class="d-flex align-center">
              <span>{{ deal.sum | VMask(priceMask) }}</span>
            </div>
          </div>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="discount"
              @change="editDealPage"
              :disabled="!hasRight('deals.manage')"
              type="number"
              suffix="%"
              hide-details
          >
            <template v-slot:label>
              Скидка
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="deliveryCost"
              @change="editDealPage"
              :disabled="!hasRight('deals.manage')"
              type="number"
              hide-details
          >
            <template v-slot:label>
              Стоимость доставки
            </template>
          </v-text-field>
          <div class="evi-toolbar__night mb-7" :class="{'active': clientPayDelivery}">
            <v-switch
                label="Клиент оплачивает доставку"
                v-model="clientPayDelivery"
                :readonly="!hasRight('deals.manage')"
                @change="editDealPage"
            ></v-switch>
          </div>
          <div class="item-page__text-field mb-5">
            <small>Итоговая сумма</small>
            <div class="d-flex align-center">
              <span>{{ deal.total_sum | VMask(priceMask) }}</span>
            </div>
          </div>
        </div>
        <div class="mb-11">
          <h4 class="mb-5">Оплата</h4>
          <v-text-field
              class="evi-text-field mb-7"
              color="#44D370"
              v-model="prepayment"
              @change="editDealPage"
              :disabled="!hasRight('deals.manage')"
              type="number"
              hide-details
          >
            <template v-slot:label>
              Предоплата
            </template>
          </v-text-field>
          <div class="item-page__text-field mb-5">
            <small>При получении</small>
            <div class="d-flex align-center">
              <span>{{ deal.payment_upon_receipt | VMask(priceMask) }}</span>
            </div>
          </div>
          <div class="item-page__text-field mb-11" v-if="deal.bills && deal.bills.length">
            <small>Счета</small>
            <div class="pointer mt-1 mb-1 linked" @click="openBillPage(bill.id)" v-for="bill in deal.bills" :key="bill.id">
              {{ bill.number }}
            </div>
          </div>
        </div>
        <Delivery :dealId="deal.id" :canEdit="hasRight('deals.manage')" :delivery="deal.delivery || {}" />
      </div>
      <div class="item-page__added">
        <v-tabs
            v-model="tab"
            align-with-title
            class="item-page__tabs"
        >
          <v-tabs-slider color="#44D370"></v-tabs-slider>
          <v-tab
              v-for="item in tabs"
              :key="item"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <Products
            v-if="tab === 0"
            :products="dealProducts"
            @openModalCreate="addProductModal = true"
            @getData="getData"
            :dealId="deal.id"
            :canEdit="hasRight('deals.manage')"
        />
        <Comments
            v-if="tab === 1"
            :comments="deal.comments"
            :moduleItemId="deal.id"
            value="deals"
            @getData="getData"
            :canEdit="hasRight('deals.manage')"
        />
      </div>
    </div>
    <v-dialog
        v-model="removeDealModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление сделки: "{{ deal.name }}"
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить сделку?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeDealModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="remove"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="addProductModal"
        max-width="612px"
    >
      <AddProduct @getData="getData" @close="addProductModal = false" :dealId="deal.id" :dealProducts="deal.products"
                  :products="products"/>
    </v-dialog>
    <v-dialog
        v-model="createBillModal"
        max-width="612px"
    >
      <CreateBill :currentDeal="deal" @close="createBillModal = false"/>
    </v-dialog>
    <v-dialog
        v-model="addFieldModal"
        max-width="612px"
    >
      <CreateField @createField="createDealCustomFields" @close="addFieldModal = false"/>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Comments from "../../components/сommon/Comments";
import AddProduct from "../../components/deals/AddProduct";
import Products from "../../components/deals/Products";
import {TimeAnswerMixin} from "@/mixins/TimeAnswerMixin";
import {OpenPagesMixin} from "@/mixins/OpenPagesMixin";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {hasRight} from '@/utils/access/hasRight';
import {UserAutocompleteRequestsMixin} from "@/mixins/autocomplete/UserAutocompleteRequestsMixin";
import {SubdivisionAutocompleteRequestsMixin} from "@/mixins/autocomplete/SubdivisionAutocompleteRequestsMixin";
import CreateBill from "../../components/bills/CreateBill";
import Delivery from "@/components/deals/Delivery";
import CustomFields from "@/components/clients/CustomFields";
import CreateField from "@/components/clients/CreateField";

const priceMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowNegative: true,
});

export default {
  name: "DealPage",
  components: {CreateField, CustomFields, Delivery, CreateBill, Products, AddProduct, Comments},
  mixins: [TimeAnswerMixin, OpenPagesMixin, UserAutocompleteRequestsMixin, SubdivisionAutocompleteRequestsMixin],
  data: () => ({
    priceMask,
    removeDealModal: false,
    addProductModal: false,
    addFieldModal: false,
    createBillModal: false,
    clientPayDelivery: false,
    name: '',
    prepayment: '',
    deliveryCost: '',
    discount: '',
    status: null,
    dealProducts: [],
    executor: {},
    commentId: null,
    subdivision: null,
    tab: 0,
    tabs: [
      'Корзина', 'Комментарии',
    ],
  }),
  computed: {
    ...mapState("deals", ['deal', 'dealCustomFields']),
    ...mapState("users", ['subdivisions']),
    ...mapState("products", ['products',]),
    ...mapState("common", ["loading"]),
    ...mapState("deal-statuses", ["statuses",]),
    localUsers() {
      if (this.users.length) {
        return this.users;
      } else if (this.deal.executor) {
        return [this.deal.executor];
      }
      return [];
    },
    dealUsers() {
      let array = [];
      if (this.deal && this.deal.products) {
        this.deal.products.map(i => {
          if (!array.some(el => el.id === i.deal_product_user.id)) {
            array.push(i.deal_product_user);
          }
        })
      }
      return array;
    },
    localSubdivisions() {
      if (this.subdivisions.length) {
        return this.subdivisions;
      } else if (this.deal.subdivision) {
        return [this.deal.subdivision];
      }
      return [];
    },
    localStatuses() {
      if (this.statuses.length) {
        return this.statuses;
      } else if (this.deal.deal_status) {
        return [this.deal.deal_status];
      }
      return [];
    }
  },
  methods: {
    ...mapActions({
      getDeal: 'deals/getDeal',
      editDeal: 'deals/editDeal',
      deleteDeal: 'deals/deleteDeal',
      getStatuses: 'deal-statuses/getStatuses',
      deleteDealCustomFields: 'deals/deleteDealCustomFields',
      getDealCustomFields: 'deals/getDealCustomFields',
      createDealCustomFields: 'deals/createDealCustomFields',
      editDealCustomFields: 'deals/editDealCustomFields',
    }),
    hasRight: hasRight,
    editField(i) {
      this.editDealCustomFields(i)
    },
    removeField(i) {
      this.deleteDealCustomFields({id: i.id, index: i.index})
    },
    changeCustomField(val) {
      if (val.value) {
        let payload = {
          id: this.deal.id,
          custom_fields: [val]
        };
        this.editDeal(payload);
      }
    },
    getData() {
      let id = this.$route.params.id;
      this.getDeal(id);
      this.getStatuses();
    },
    editDealPage() {
      let payload = {
        name: this.name || this.deal.name,
        client_id: this.deal.client.id,
        executor_id: this.executor || this.deal.executor.id,
        products: this.dealProducts,
        id: this.deal.id,
        subdivision_id: this.subdivision,
        prepayment: this.prepayment,
        discount: this.discount,
        delivery_cost: this.deliveryCost,
        client_pay_delivery: this.clientPayDelivery,
      };
      if (this.status) {
        payload.deal_status_id = this.status;
      }
      this.editDeal(payload);
    },
    remove() {
      this.deleteDeal(this.deal).then(() => {
        this.removeDealModal = false;
        this.$router.push({path: '/deals'});
      })
    },
  },
  watch: {
    deal(val) {
      this.getDealCustomFields();

      this.name = val.name;
      this.prepayment = val.prepayment;
      this.discount = val.discount;
      this.deliveryCost = val.delivery_cost;
      this.clientPayDelivery = val.client_pay_delivery;
      this.dealProducts = val.products;
      this.executor = val.executor.id;
      if (val.subdivision) {
        this.subdivision = val.subdivision.id;
      }
      if (val.deal_status) {
        this.status = val.deal_status.id;
      }
    },
    '$route.params.id': {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true
    },
    'dealCustomFields': {
      handler: function (val) {
        // значения кастомных полей устанавливается тут
        if (this.deal.custom_fields && this.deal.custom_fields.length) {
          this.deal.custom_fields.forEach(i => {
            let field = val.find(f => f.id === i.id);
            if (field) {
              if (field.type === 'datetime') {
                field.value = `${this.$moment(i.value).format('DD.MM.YYYY')} ${this.$moment(i.value).format('HH:mm')}`;
              } else {
                field.value = i.value;
              }
            }
          });
        }
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
.item-page {
  &__edit {
    border-right: 1px solid #EBEBEC;
  }

  .evi-autocomplete {
    max-width: 262px;
  }
  .v-input--selection-controls {
    margin-top: 0;
  }
}
</style>
